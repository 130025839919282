import React from "react";
import CommonBanner from "../component/Common/Banner";
import PostDriver from "../component/postDriver/PostDriver";
const Post = () => {
  return (
    <>
      <CommonBanner />
      <PostDriver />
    </>
  );
};

export default Post;
